$('.map').mapify({
    points: [
        {
            lat: 54.00158,
            lng: -0.920750,
            marker: true,
        }
    ],
    key: 'AIzaSyDxdqMhRs4FsefWXG8nbNKtUbux_kGpv6k',
    zoom: 12
});