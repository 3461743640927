(function($) {
    $(document).ready(function() {
        $('.button-coral').on('click', function(){
            $(".table").toggleClass('-closed');
            $(".table-text").toggleClass('-closed');
        });
    });
    $(document).ready(function(){
        $('.table').addClass('-closed');
        $(".table-text").toggleClass('-closed');
    });
})(jQuery);
